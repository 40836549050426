import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () => import("./views/documents/privacy-policy/index.vue"),
    },
    {
      path: "/terms-of-use",
      name: "terms-of-use",
      component: () => import("./views/documents/terms-of-use/index.vue"),
    },
    {
      path: "/public-offer",
      name: "public-offer",
      component: () => import("./views/documents/public-offer/index.vue"),
    },
    {
      path: "/catalog/:id?",
      name: "catalog",
      component: () => import("./views/catalog/open/index.vue"),
    },
    {
      path: "/thanks",
      name: "thanks",
      component: () => import("./views/thanks/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/requisites",
      name: "requisites",
      component: () => import("./views/requisites/index.vue"),
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("./views/profile/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/profile/education",
      name: "account_education",
      component: () => import("./views/profile/education/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/account/education/list/:id",
      name: "account_education_list",
      component: () => import("./views/profile/education/list/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/account/education/open/:module/:lesson",
      name: "account_education_open",
      component: () => import("./views/profile/education/open/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/account/completed",
      name: "account_completed",
      component: () => import("./views/profile/completed/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/recovery",
      name: "recovery",
      component: () => import("./views/recovery/index.vue"),
    },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
