<template>
  <header
    :class="{
      header__bg: $route.name === 'home',
      header__404: $route.name === 'notfound',
      'header-fixed': scrollingPage,
    }"
    class="header"
  >
    <div class="header-mobile" v-if="showBurgerMenu">
      <div class="header-mobile__menu" v-click-outside="closeBurgerMenu">
        <div class="header-mobile__menu-top">
          <div class="header-mobile__menu-logo" @click="closeBurgerMenu">
            <IconComponent name="logo" />
          </div>
          <div @click="closeBurgerMenu" class="header-mobile__menu-close">
            <IconComponent name="close" />
          </div>
        </div>
        <div class="header-mobile__profile">
          <span v-if="!user" @click="openLoginModal" class="header-mobile__login btn--main header__login">
            Войти
          </span>
          <span
            class="header-mobile__user"
            :class="{ 'header-mobile__user--active': showUserMobileMenu }"
            @click.prevent="toggleUserMenu"
            v-if="user"
            v-click-outside="UserMobileMenuHide"
          >
            <!--              <ImgComponent src="/static/images/profile-user.png" class="header__user-image" />-->
            <span class="header-mobile__user-name">{{ user.name }} {{ user.surname }}</span>
            <IconComponent name="DownIconSmall" class="header-mobile__user-icon" />
            <UserMenuComponent :active="showUserMobileMenu" @close="closeBurgerMenu" @logout="logout" />
          </span>
        </div>
        <div class="header-mobile__menu-links">
          <router-link :to="{ name: 'home', hash: '#catalog' }">
            <span @click="closeBurgerMenu">Каталог курсов</span>
          </router-link>
          <a @click="openModal" class="header-mobile__menu-links-line">
            <span>Консультация</span>
          </a>
        </div>
        <div class="header-mobile__menu-info">
          <div class="header-mobile__menu-contacts">
            <a
              class="header-mobile__menu-contacts__phone"
              v-if="info?.phone"
              :href="('tel:' + info.phone) | formatNumber"
            >
              {{ info.phone }}
            </a>
            <a
              class="header-mobile__menu-contacts__email"
              v-if="info?.email"
              :href="('mailto:' + info.email) | formatNumber"
            >
              {{ info.email }}
            </a>
          </div>
          <div class="header-mobile__menu-socials">
            <SocialsComponent :socials="socials" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="header__content">
        <div class="header__logo">
          <router-link class="header__logo-link" :to="{ name: 'home' }">
            <IconComponent name="logo" />
          </router-link>
          <div class="header__nav">
            <router-link :to="{ name: 'home', hash: '#catalog' }">Каталог курсов</router-link>
            <a @click="openModal" class="header__nav-consultation">Консультация</a>
          </div>
        </div>
        <div class="header__right">
          <div class="header__info">
            <div class="header__contacts">
              <a v-if="info && info.email" :href="('mailto:' + info.email) | formatNumber">
                {{ info.email }}
              </a>
              <div class="header__contact">
                <a v-if="info && info.phone" :href="('tel:' + info.phone) | formatNumber">{{ info.phone }}</a>
                <div
                  v-if="info && info.qr_img"
                  :class="{
                    'header__contact-item--dark': $route.name !== 'home' || scrollingPage,
                  }"
                  class="header__contact-item"
                >
                  <a :href="('tel:' + info.phone) | formatNumber">
                    <img :src="info.qr_img.path" alt="qr-code" class="header__qr" />
                  </a>
                  <span class="header__contact-text">
                    Наведите камеру телефона на QR, чтобы начать звонок
                  </span>
                </div>
              </div>
            </div>
            <div class="header__socials">
              <SocialsComponent :socials="socials" />
            </div>
          </div>
          <div class="header__profile">
            <span class="btn--main header__login" @click="openLoginModal" v-if="!user">Войти</span>
            <span
              class="header__user"
              :class="{ 'header__user--active': showUserMenu }"
              @click.prevent="toggleUserMenu"
              v-if="user"
              v-click-outside="UserMenuHide"
            >
              <span class="header__user-name">{{ user.name }}</span>
              <IconComponent name="DownIconSmall" class="header__user-icon" />
              <UserMenuComponent :active="showUserMenu" @logout="logout" />
            </span>
          </div>
          <div @click="toggleBurgerMenuOpen" class="header__burger">
            <IconComponent name="burger" />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import UserMenuComponent from "components/UserMenuComponent.vue";
import ClickOutside from "vue-click-outside";
import ConsultationModal from "components/modals/components/ConsultationModal.vue";
import SocialsComponent from "components/SocialsComponent.vue";

export default {
  name: "HeaderComponent",
  components: { SocialsComponent, UserMenuComponent, IconComponent },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      // transparency: true,
      scrollingPage: false,
      showUserMenu: false,
      headerBg: true,
      showBurgerMenu: false,
      showUserMobileMenu: false,
      contacts: {
        phone: "+7 (987) 65 43 210",
        email: "support@aistclub.ru",
      },
    };
  },
  mounted() {
    if (this.$route.name === "home") {
      this.headerBg = false;
    }
    window.addEventListener("scroll", this.editHeader);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    info() {
      return this.$store.state.info;
    },
    socials() {
      return this.$store.state.info.socials;
    },
  },
  methods: {
    editHeader() {
      this.scrollingPage = window.scrollY >= 1;
    },
    openModal() {
      this.$store.state._modals.push({
        component: ConsultationModal,
      });
    },
    closeBurgerMenu() {
      this.showBurgerMenu = false;
      this.showUserMobileMenu = false;
      document.body.classList.toggle("lock-scroll");
    },
    toggleBurgerMenuOpen() {
      this.showBurgerMenu = !this.showBurgerMenu;
      document.body.classList.toggle("lock-scroll");
    },
    openLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
      });
    },
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
      this.showUserMobileMenu = !this.showUserMobileMenu;
    },
    UserMenuHide() {
      this.showUserMenu = false;
    },
    UserMobileMenuHide() {
      this.showUserMobileMenu = false;
    },
    logout() {
      if (confirm("Вы уверены?")) {
        require(["axios"], (axios) => {
          axios.default({
            method: "post",
            url: "/token",
            data: {
              token: undefined,
            },
            headers: {
              "Content-Type": "application/json",
            },
          });
        }).then(() => {
          this.$store.state.user = null;
          if (this.$route.name !== "home") {
            this.$router.push({
              name: "home",
            });
          }
          this.$notify({
            title: "Готово",
            duration: 5000,
            speed: 200,
            type: "success",
          });
          this.showBurgerMenu = false;
          this.showUserMenu = false;
        });
      }
    },
  },
};
</script>

<style lang="stylus">
.lock-scroll {
  overflow hidden
}

//header {
//  background var(--dark)
//  position: relative;
//}
.header-fixed {
  position: fixed !important;
  top 0 !important
  background var(--dark) !important
}
.header {
  width 100%
  height 110px
  z-index 200
  background var(--dark)
  position: fixed;
  top 0

  +below(700px) {
    height 90px
  }

  &__bg {
    background none
    position absolute
  }

  &__404 {
    display none
  }

  &__content{
    display flex
    justify-content space-between
    position absolute
    align-items center
    z-index 222
    height 50px
    margin-top: 28px;
    width 100%
    +below(1350px) {
      padding 0 20px
    }

    +below(700px) {
      margin-top: 20px
    }
  }

  &__logo-link{
    display flex
    align-items center
  }

  &__logo{
    display flex
    gap 60px
    align-items center

    & .icon{
      +below(1280px){
        max-width 195px
        max-height 40px
      }
      +below(1100px){
        max-width 120px
        max-height 30px
      }
      //& svg path {
      //  +below(1280px){
      //    fill var(--white)
      //  }
      //
      //  +below(700px){
      //    fill var(--pink)
      //  }
      //}
    }
  }

  &__nav{
    display flex
    gap 30px

    +below(1200px) {
      display none
    }

    &-consultation{
      text-decoration-line: underline;
    }

    //a {
    //  font-weight: 500;
    //}
  }

  &__right {
    display flex
    align-items center
    gap 40px
  }

  &__info {
    display flex
    gap 55px
    align-items center
  }

  &__contacts {
    display flex
    gap 30px
    +below(700px) {
      display none
    }
  }

  &__contact {
    display flex
    flex-direction column
    position relative

    a {
      z-index 1
    }

    &:hover {
      .header__contact-item {
        opacity 1
        visibility visible
      }
    }
  }

  &__contact-item {
    opacity 0
    transition var(--transition)
    visibility hidden
    width 182px
    position absolute
    top -10px
    left 50%
    transform translateX(-50%)
    padding 48px 11px 14px 11px
    background var(--white_o7)
    color var(--dark)
    border-radius 16px
    display flex
    flex-direction column
    align-items center
    gap 12px
    &--dark {
      background var(--dark)
      color var(--white)
    }
  }

  &__contact-text {
    text-align center
    font-size 0.875em
    font-weight 700
    line-height 18px
  }

  &__qr {
    width 123px
    height 123px
  }

  &__socials {
    display flex
    gap 37px
    +below(1100px) {
      display none
    }

    & .icon{
      cursor: pointer;
      max-width: 30px;
    }
  }

  &__burger {
    display none
    +below(1100px) {
      display flex
    }

    .icon {
      width 100%
      max-width 32px
      height 100%
      max-height 30px
      fill var(--white)

      +below(700px) {
        width 32px
        fill var(--pink)
      }
    }
  }
  &__profile{
    display flex
    +below(1100px){
      display none
    }
  }

  &__login {
    padding 10px 20px
    background var(--pink)
    border-radius 5px
    color var(--white)
    cursor: pointer;
    transition var(--transition)
  }

  &__user {
    display flex
    //grid-template-columns 44px 1fr 24px
    align-items center
    //grid-gap 10px
    position relative

    +below(1100px){
      display none
    }

    ^[0]__right-side & {
      +below(830px) {
        display none
      }
    }

    &--active &-icon {
      transform rotate(180deg)
    }

    &-image {
      width 44px
      height 44px
      border-radius 100%
      object-fit cover
      object-position center
    }

    &-name {
      font-weight: normal;
      font-size: 1em;
      line-height: 19px;
      color: var(--white);
      cursor: pointer;
    }

    &-icon {
      width 24px
      height 24px
      transition .2s

      svg {
        width 100%
        height 100%
      }
    }
  }
}
.header-mobile{
  width 100%
  background var(--black_o3)
  position fixed
  z-index 333
  height: 100vh;
  display flex
  justify-content flex-end
  overflow-y visible

  &__profile{
    display flex
    justify-content left
    padding-left: 20px

    +above(1100px){
      display none
    }
  }

  &__login{
    font-size: 1.5em;
    font-weight: 500
  }

  &__user{
    display flex
    //grid-template-columns 44px 1fr 24px
    align-items center
    gap 5px
    position relative

    &--active &-icon {
      transform rotate(180deg)
    }

    & .user-menu{
      left: 0
    }


    &-image {
      width 44px
      height 44px
      border-radius 100%
      object-fit cover
      object-position center
    }

    &-name {
      font-weight: 500
      font-size: 1.500em;
      line-height: 19px;
      color: var(--dark);
      cursor: pointer;
      display: flex;
      justify-content center
    }

    &-icon {
      width 24px
      height 24px
      transition .2s

      svg {
        width 100%
        height 100%
      }
      path {
        stroke var(--dark)
      }
    }
  }

  &__menu {
    width 100%
    max-width 360px
    height 100%
    display flex
    flex-direction column
    justify-content space-between
    padding 30px 20px
    background var(--white)
    +below(700px) {
      padding 30px 15px 120px 15px
    }

    &-top {
      display flex
      //align-items center
      justify-content flex-end
      +below(700px) {
        justify-content space-between
        align-items center
      }
    }

    &-logo {
      display none
      +below(700px) {
        display flex
      }
      .icon {
        width 100%
        max-width 98px
        height 100%
        max-height 20px
        fill var(--pink)
      }
    }

    &-close {
      //display flex
      //justify-content flex-end
      max-width 30px
      max-height 30px
      +below(700px) {
        max-width 20px
        max-height 20px
      }
      .icon {
        cursor: pointer;
        svg {
          width 30px
          height 30px
          +below(700px) {
          width 20px
          height 20px
          }
        }
        fill var(--pink)
      }
    }

    &-links {
      text-transform uppercase
      display flex
      flex-direction column
      align-items flex-start
      padding-left: 20px
      gap 20px
      +below(700px) {
      text-transform none
      }

      &-line{
        text-decoration underline
      }

      a {
        color var(--dark)
        font-size 1.5em
        line-height 130%
        font-weight 500
      }
    }

    &-info{
      display flex
      flex-direction column
      gap 50px
    }

    &-contacts {
      display none
      +below(700px) {
        display flex
        flex-direction column
        align-items flex-start
        gap 20px
        font-size 1.5em
        line-height 30px
        font-weight 500
        padding-left: 20px
      }
      a {
        color var(--dark)
      }
      &__phone {
        font-weight 700
      }
      &__email {
        font-weight 500
      }
    }
    &-socials {
      display flex
      justify-content start
      padding-left 20px
      gap 20px

      & .socials {
        .icon {
          max-width 25px
          max-height 25px
          cursor: pointer;
          svg {
            width 100%
            height 100%
            path {
              fill var(--pink)
            }
          }
        }
      }
    }
  }
}
</style>
