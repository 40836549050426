import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default function createStore(state) {
  return new Vuex.Store({
    state() {
      return (
        state || {
          _modals: [],
          user: null,
          info: {
            socials: [],
          },
          tariffs: [],
          search_promocode: {},
          courses: [],
          FAQ: {},
          catalog_page: {
            reviews: [],
          },
          courses_item: [],
          home_page: {
            courses: [],
            reviews: [],
          },
          tmp: {},
          _colors: {
            CODE_COLOR_PINK: 1,
            CODE_COLOR_LIGHT_GREY: 2,
            CODE_COLOR_DARK_GREY: 3,
            CODE_COLOR_RED: 4,
            CODE_COLOR_WHITE: 5,
          },
          _types: {
            CODE_ADDITIONALS_FOR_WHOM: 1,
            CODE_ADDITIONALS_WHAT_LEARN: 2,
            CODE_ADDITIONALS_REQUIREMENTS: 3,
            CODE_ADDITIONALS_SKILLS: 4,
            CODE_COURSE_EASY: 1,
            CODE_COURSE_MEDIUM: 2,
            CODE_COURSE_HARD: 3,
            CODE_TEST_TEXT_INPUT: 1,
            CODE_TEST_SELECTING_ONE: 2,
            CODE_TEST_SELECTING_FEW: 3,
            CODE_TEST_FILE_UPLOAD: 4,
            CODE_COURSE_FREE: 1,
            CODE_COURSE_PAYABLE: 2,
            CODE_SUBSCRIBE: 1, // Подписаться
            CODE_CONSULTATION: 2, // Консультация
            CODE_DOWNLOAD: 3, // Скачать программу
          },
          _loading_types: {
            DEFAULT: 1,
            PAGINATION: 2,
            LOAD_MORE: 3,
          },
        }
      );
    },
  });
}
