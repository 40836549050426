<template>
  <div class="registration-form">
    <button class="registration-form__close" @click="closeModal">
      <IconComponent name="close-square" />
    </button>
    <form
      @submit.prevent="submit"
      class="registration-form__content"
      :class="{ 'registration-form--token': tempToken }"
    >
      <div class="registration-form__text">
        <b class="registration-form__text-title">Регистрация</b>
        <span v-if="tempToken" class="registration-form__text-subtitle">
          Введите код, отправленный вам на почту
        </span>
        <span v-else class="registration-form__text-subtitle">Для регистрации заполните все поля формы</span>
      </div>
      <div class="registration-form__list">
        <template v-if="tempToken">
          <InputComponent
            v-model.trim="form.code.value"
            :errors="form.code.errors"
            placeholder="Код подтверждения"
          />
        </template>
        <template v-else>
          <div class="registration-form__list-name">
            <InputComponent v-model="form.name.value" :errors="form.name.errors" placeholder="Имя" />
            <InputComponent
              v-model="form.surname.value"
              :errors="form.surname.errors"
              placeholder="Фамилия"
            />
          </div>
          <div class="registration-form__list-contact">
            <InputComponent v-model="form.email.value" :errors="form.email.errors" placeholder="E-mail" />
            <InputComponent
              v-model="form.phone.value"
              :errors="form.phone.errors"
              inputmode="numeric"
              placeholder="Номер телефона"
              mask="+7 (###)-###-##-##"
            />
          </div>
          <div class="registration-form__list-password">
            <InputComponent
              v-model="form.password.value"
              :errors="form.password.errors"
              placeholder="Пароль"
              :type="form.password.show ? 'text' : 'password'"
              @submit="form.password.show = !form.password.show"
            >
              <template v-slot:action="">
                <IconComponent name="EyeIcon" v-if="form.password.show" />
                <IconComponent name="EyeCloseIcon" v-else />
              </template>
            </InputComponent>
            <InputComponent
              v-model="form.password_confirmation.value"
              :errors="form.password_confirmation.errors"
              placeholder="Подтвердите пароль"
              :type="form.password_confirmation.show ? 'text' : 'password'"
              @submit="form.password_confirmation.show = !form.password_confirmation.show"
            >
              <template v-slot:action="">
                <IconComponent name="EyeIcon" v-if="form.password_confirmation.show" />
                <IconComponent name="EyeCloseIcon" v-else />
              </template>
            </InputComponent>
          </div>
        </template>
      </div>
      <div class="registration-form__buttons">
        <template v-if="tempToken">
          <button class="btn btn--main" type="submit" :disabled="!form.checkbox.value">
            <LoadingIndicator title="Вход" v-show="loading" />
            <span v-show="!loading">Войти</span>
          </button>
        </template>
        <template v-else>
          <button class="btn btn--main" type="submit" :disabled="!form.checkbox.value">
            <LoadingIndicator title="Регистрация" v-show="loading" />
            <span v-show="!loading">Зарегистрироваться</span>
          </button>
        </template>
        <div class="registration-form__checkbox">
          <CheckboxComponent v-model="form.checkbox.value">
            <span>
              Соглашаюсь с
              <router-link :to="{ name: 'privacy-policy' }"> политикой конфиденциальности </router-link>
            </span>
          </CheckboxComponent>
        </div>
        <span class="registration-form__links"
          >Уже зарегистрированы? <a @click="openLoginModal">Войти</a></span
        >
        <router-link :to="{ name: 'terms-of-use' }" class="registration-form__links"
          >Условия использования</router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import LoadingIndicator from "components/LoadingIndicator.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import TRY_AUTH from "@/graphql/mutations/UserTryAuth.graphql";
import POST from "@/graphql/mutations/UserRegister.graphql";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "RegistrationForm",
  components: { IconComponent, CheckboxComponent, InputComponent, LoadingIndicator },
  data() {
    return {
      loading: false,
      tempToken: false,
      tempEmail: null,
      form: {
        code: {
          value: null,
          required: true,
          errors: [],
        },
        name: {
          value: null,
          required: true,
          errors: [],
        },
        surname: {
          value: null,
          required: true,
          errors: [],
        },
        email: {
          value: null,
          required: true,
          errors: [],
        },
        phone: {
          value: "",
          required: true,
          errors: [],
        },
        password: {
          show: false,
          value: null,
          required: true,
          errors: [],
        },
        password_confirmation: {
          show: false,
          value: null,
          required: true,
          errors: [],
        },
        checkbox: {
          value: true,
          errors: [],
        },
      },
    };
  },
  watch: {
    "form.phone.value"(newValue, oldValue) {
      if (newValue.charAt(0) === "8" && oldValue.charAt(0) !== "8") {
        this.form.phone.value = "+7" + newValue.slice(1);
      }
    },
  },
  methods: {
    closeModal() {
      this.$store.state._modals = [];
    },
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    submit() {
      if (!this.tempToken) {
        this.loading = true;
        this.resetErrors();
        this.$apollo
          .mutate({
            mutation: POST,
            variables: {
              name: this.form.name.value,
              surname: this.form.surname.value,
              email: this.form.email.value,
              phone: this.form.phone.value,
              password: this.form.password.value,
              password_confirmation: this.form.password_confirmation.value,
            },
          })
          .then(({ data }) => {
            this.tempEmail = this.form.email.value;
            this.tempToken = data.UserRegister.token;
            this.loading = false;
            this.$notify({
              title: "Готово",
              text: "На почту отправлен код подтверждения",
              duration: 5000,
              speed: 200,
              type: "success",
            });
          })
          .catch(({ graphQLErrors }) => {
            this.$notify({
              title: "Ошибка",
              duration: 5000,
              speed: 200,
              type: "error",
            });
            this.parseGqlErrors(graphQLErrors);
            this.loading = false;
          });
      } else {
        this.loading = true;
        this.resetErrors();
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: TRY_AUTH,
            variables: {
              token: this.tempToken,
              email: this.form.email.value,
              code: this.form.code.value,
            },
          })
          .then(({ data }) => {
            if (data && data.UserTryAuth) {
              this.$store.state.user = data.UserTryAuth.user;
              this.$store.state.apollo_token = data.UserTryAuth.token;

              // Сохраняю токен в сессии
              require(["axios"], (axios) => {
                axios.default({
                  method: "post",
                  url: "/token",
                  data: {
                    token: data.UserTryAuth.token,
                  },
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
              });
            }
            this.$notify({
              title: "Готово",
              text: `Вы вошли как ${this.$store.state.user.name} ${this.$store.state.user.surname}`,
              duration: 5000,
              speed: 200,
              type: "success",
            });
            this.$emit("close");
          })
          .catch(({ graphQLErrors }) => {
            this.$notify({
              title: "Ошибка",
              text: "Не правильный код",
              duration: 5000,
              speed: 200,
              type: "error",
            });
            this.parseGqlErrors(graphQLErrors);
            this.loading = false;
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors.push(err.extensions.validation[key][0]);
            }
          });
        }
      });
    },
    openLoginModal() {
      this.$store.state._modals = [];

      this.$store.state._modals.push({
        component: LoginModal,
      });
    },
  },
};
</script>

<style lang="stylus">
.registration-form {
  margin-top 50px
  width 100%
  max-width 780px
  z-index 1000
  display flex
  flex-direction column
  align-items flex-end

  &__content {
    width 100%
    background-color var(--white)
    border-radius 20px
    padding 60px
    display flex
    flex-direction column
    gap 35px
    +below(650px) {
      padding 15px
    }
  }

  &__close {
    margin-top 80px
    width 40px
    height 40px
    padding 13px
    z-index 1
    background none
    border none
    transition var(--transition)
    cursor pointer

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--white)
        transition var(--transition)
      }
    }

    &:hover {
      .icon svg path {
        fill var(--pink)
      }
    }
  }

  & .select__field::placeholder{
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark);
    opacity: 0.3;
  }

  +below(800px){
    padding 15px
    max-width 354px
    gap 15px
  }

  &__links{
    font-size 1em
    +below(800px){
      display none
      font-size: 0.875em
    }
  }

  &--token {
    margin-top: 0
  }

  &__fio {
    display flex
    flex-direction column
    gap 20px
    width: 100%;
  }

  &__fio-title {
    text-align center
    font-weight 500
    font-size 1.125em
  }

  & .btn {
    max-width 100%
    height 50px
    font-size: 1em
  }

  &__buttons{
    font-size 1.125em
    font-weight 500
    display flex
    flex-direction column
    gap 15px

    & a {
      cursor pointer
      color var(--black)
      text-decoration underline
    }
    & span{
      display flex
      gap 15px
      +below(800px) {
        flex-direction column
        gap 5px
      }
    }

    & .btn:disabled{
      border 1px solid transparent
    }

    .icon {
      width 24px !important
      height 24px !important

      svg path {
        fill var(--white) !important
      }
    }
  }

  &__text {
    display flex
    flex-direction column
    text-align center
    gap 25px

    +below(800px){
      gap 15px
    }

    &-title {
      font-size: 2.500em
      line-height: 47px;

      +below(800px){
        font-size: 1.875em;
        line-height: 35px;
      }
    }
    &-subtitle {
      font-size: 1.500em;
      font-weight 500
      line-height: 28px;

      +below(800px){
        font-size: 0.750em
        line-height: 14px;
      }
    }
  }

  &__checkbox {

    & .check{
      gap 6px
    }

    & span{
      font-weight: 400;
      font-size: 1em
      gap 2px

      +below(800px){
        display: flex
        flex-direction column
        font-size: 0.8em
      }
      +below(400px){
        font-size: 0.6em
      }
    }

    a {
      color var(--black)
      text-decoration underline
    }
  }

  &__list{
    display flex
    flex-direction column
    gap 20px

    +below(800px){
      gap 15px
    }

    &-name{
      display flex
      gap 20px
      width 100%

      +below(800px){
        flex-direction column
      }

      & .input{
        width 100%
      }
    }
    &-geo{
      display flex
      flex-direction column
      gap 20px
    }
    &-contact{
      display flex
      flex-direction column
      gap 20px
    }
    &-password{
      display flex
      flex-direction column
      gap 20px
    }
  }
  .select__container {
    width 100%
    height auto

    .select__field-container {
      width 100%
      height auto
    }
  }
}
</style>
