<template>
  <div class="socials" v-if="socials">
    <a v-for="(item, i) in socials" :key="i" :href="item.link" target="_blank">
      <figure class="icon" v-html="item.icon"></figure>
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialsComponent",
  props: {
    socials: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus">
.socials {
  display flex
  align-items center
  gap 7px
  justify-content flex-start

  a {
    display flex
    align-items center
    justify-content center
    width 40px
    height 40px
    border-radius var(--main-radius)
    border 1px solid var(--border-color)

    &:hover {
      border-color var(--main)
    }

    .icon {
      width 50px
      height 50px

      svg path {
        fill var(--white)
      }

      &:hover {
        svg path {
          transition var(--transition)
          fill var(--pink)
        }
      }
    }
  }
}
</style>
