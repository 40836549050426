<template>
  <div class="m consultation-modal">
    <button class="consultation-modal__close" @click="closeModal">
      <IconComponent name="close-square" />
    </button>
    <div class="m__content consultation-modal__content">
      <div class="m__header">
        <span class="m__subtitle">Заполните форму</span>
      </div>
      <form class="m__body" @submit.prevent="submit">
        <div class="m__field">
          <InputComponent v-model="form.name.value" :errors="form.name.errors" placeholder="Имя и Фамилия" />
          <InputComponent v-model="form.email.value" :errors="form.email.errors" placeholder="Почта" />
          <InputComponent
            v-model="form.phone.value"
            :errors="form.phone.errors"
            placeholder="+7 (_ _ _) _ _ _-_ _-_ _"
            mask="+7 (###)-###-##-##"
          />
        </div>
        <button class="btn btn--main btn--md consultation-modal__btn" :disabled="!form.checkbox.value">
          <span v-if="!loading">Отправить</span>
          <LoadingIndicator title="Отправляем" v-show="loading" />
        </button>
        <div class="consultation-modal__checkbox">
          <CheckboxComponent v-model="form.checkbox.value">
            <span>
              Соглашаюсь с
              <router-link :to="{ name: 'privacy-policy' }"> политикой конфиденциальности </router-link>
            </span>
          </CheckboxComponent>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import POST from "@/graphql/mutations/ClaimCreate.graphql";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
export default {
  name: "ConsultationModal",
  components: { CheckboxComponent, LoadingIndicator, InputComponent, IconComponent },
  data() {
    return {
      loading: false,
      form: {
        name: {
          value: null,
          required: true,
          errors: [],
        },
        email: {
          value: null,
          required: true,
          errors: [],
        },
        phone: {
          value: "",
          required: true,
          errors: [],
        },
        checkbox: {
          value: true,
          required: true,
          errors: [],
        },
      },
    };
  },
  watch: {
    "form.phone.value"(newValue, oldValue) {
      if (newValue.charAt(0) === "8" && oldValue.charAt(0) !== "8") {
        this.form.phone.value = "+7" + newValue.slice(1);
      }
    },
  },
  methods: {
    closeModal() {
      this.$store.state._modals = [];
    },
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors.push(err.extensions.validation[key][0]);
            }
          });
        }
      });
    },
    submit() {
      this.loading = true;
      this.resetErrors();
      this.$apollo
        .mutate({
          mutation: POST,
          variables: {
            phone: this.form.phone.value,
            name: this.form.name.value,
            email: this.form.email.value,
          },
        })
        .then(() => {
          this.loading = false;
          this.$notify({
            title: "Готово",
            duration: 5000,
            speed: 200,
            type: "success",
          });
          this.$store.state._modals = [];
        })
        .catch(({ graphQLErrors }) => {
          this.$notify({
            title: "Ошибка",
            duration: 5000,
            speed: 200,
            type: "error",
          });
          this.parseGqlErrors(graphQLErrors);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="stylus">
.consultation-modal{
  display flex
  flex-direction column
  align-items flex-end

  &__content {
    display flex
    flex-direction column
    gap 20px
  }

  &__close {
    width 40px
    height 40px
    padding 13px
    z-index 1
    background none
    border none
    transition var(--transition)
    cursor pointer

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--white)
        transition var(--transition)
      }
    }

    &:hover {
      .icon svg path {
        fill var(--pink)
      }
    }
  }

  & span{
    text-align center
  }
  &__btn{
    width 100%

    &:hover{
      .icon{
        svg path {
          fill var(--black)
        }
      }
    }

    &:disabled{
      background var(--pink)
    }
  }

  &__checkbox {
    width 100%

    & .check{
      gap 6px
    }

    & span{
      font-weight: 400;
      font-size: 1em
      gap 2px

      +below(800px){
        font-size: 0.8em
      }
      +below(400px){
        font-size: 0.6em
      }
    }

    a {
      color var(--black)
      text-decoration underline
    }
  }
}
</style>
