<template>
  <a href="https://comrades.dev" target="_blank" class="comrades">
    <IconComponent name="comrades" class="comrades-logo-icon" />
    <div class="comrades-text">
      <span class="comrades-text__title">Разработано</span>
      <span class="comrades-text__subtitle">COMRADES DEVS</span>
    </div>
  </a>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "ComradesLogo",
  components: { IconComponent },
  data() {
    return {
      title: {
        ru: "Разработано",
        en: "Created by",
      },
    };
  },
};
</script>

<style lang="stylus">
.comrades {
  display flex
  gap 15px
  align-items center
  --blue: #132F63

  .comrades-logo-icon {
    width 100%
    max-width 44px
    height 100%
    max-height 44px
  }

  &-text {
    display flex
    flex-direction column
    color var(--white)
    gap 5px
    transition var(--transition)

    &:hover {
      color var(--pink)
    }

    &__title {
      font-size 0.858em
      line-height 14px
    }

    &__subtitle {
      font-weight 500
      line-height 16px
    }
  }
}
</style>
