<template>
  <div
    class="user-menu"
    :class="{ 'user-menu--active': active, 'user-menu--static': statical }"
    @click="$emit('close')"
  >
    <router-link :to="{ name: 'profile' }" class="user-menu__link">
      <IconComponent name="UserIcon" />
      <span>Профиль</span>
    </router-link>
    <router-link :to="{ name: 'account_education' }" class="user-menu__link">
      <IconComponent name="GraduateIcon" />
      <span>Мое обучение</span>
    </router-link>
    <router-link :to="{ name: 'account_completed' }" class="user-menu__link">
      <IconComponent name="Archive" />
      <span>Архив покупок</span>
    </router-link>
    <a @click.prevent="$emit('logout')" class="user-menu__link">
      <IconComponent name="Logout" />
      <span>Выйти</span>
    </a>
  </div>
</template>
<script>
import IconComponent from "components/IconComponent.vue";
import ClickOutside from "vue-click-outside";

export default {
  name: "UserMenuComponent",
  directives: {
    ClickOutside,
  },
  components: { IconComponent },
  props: {
    statical: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
  },
  methods: {},
};
</script>

<style lang="stylus">
.user-menu {
  background: var(--white);
  box-shadow: 0 0 8px var(--black_o1);
  border-radius: var(--main_radius);
  absolute right bottom
  display grid
  min-width 200px
  z-index 3
  opacity 0
  visibility hidden
  transition .2s
  transform translateY(100%)

  &--active {
    opacity 1
    visibility visible
    transform translateY(calc(100% + 20px)) // 20 - отступ заголовка

    //+below(1100px){
    //  transform translateY(calc(100%)) // 20 - отступ заголовка
    //}
  }

  &--static {
    transform none
    relative left top right bottom
    width 100%
    border-bottom-left-radius 0
    border-bottom-right-radius 0
  }

  &__link {
    padding 15px
    cursor pointer
    color var(--dark)
    width 100%
    display flex
    align-items center
    gap 10px

    &:not(:last-child) {
      padding-bottom 8px
      border-bottom 1px solid #E5E5E5
    }
  }
}
</style>
