<template>
  <footer :class="{ footer__404: $route.name === 'notfound' }" class="footer">
    <div class="container">
      <div class="footer__header">
        <div class="footer__logo">
          <router-link :to="{ name: 'home' }">
            <IconComponent name="logo" />
          </router-link>
        </div>
        <router-link :to="{ name: 'home', hash: '#catalog' }">Каталог курсов </router-link>
      </div>
      <div class="footer__info">
        <div class="footer__socials">
          <SocialsComponent :socials="socials" />
        </div>
        <div class="footer__contact">
          <div v-if="info?.email" class="footer__wrapper">
            <span class="footer__contact-title">Пишите:</span>
            <a :href="('mailto:' + info.email) | formatNumber">
              <span class="footer__contact-subtitle">{{ info.email }}</span>
            </a>
          </div>
          <div v-if="info?.phone" class="footer__wrapper">
            <span class="footer__contact-title">Звоните:</span>
            <a :href="('tel:' + info.phone) | formatNumber">
              <span class="footer__contact-subtitle">{{ info.phone }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <a class="btn btn--main btn--lg footer__bottom-consultation" @click.prevent="openConsultationModal">
          <span>Консультация</span>
        </a>
        <ComradesLogo />
        <div class="footer__link">
          <router-link :to="{ name: 'privacy-policy' }">Политика конфиденциальности</router-link>
          <router-link :to="{ name: 'terms-of-use' }">Условия использования</router-link>
          <router-link :to="{ name: 'public-offer' }">Публичная оферта</router-link>
          <router-link :to="{ name: 'requisites' }">Реквизиты</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ConsultationModal from "components/modals/components/ConsultationModal.vue";
import SocialsComponent from "components/SocialsComponent.vue";
import ComradesLogo from "components/ComradesLogo.vue";

export default {
  name: "FooterComponent",
  components: { ComradesLogo, SocialsComponent, IconComponent },
  computed: {
    info() {
      return this.$store.state.info;
    },
    socials() {
      return this.$store.state.info.socials;
    },
  },
  methods: {
    openConsultationModal() {
      this.$store.state._modals.push({
        component: ConsultationModal,
      });
    },
  },
};
</script>

<style lang="stylus">
.footer {
  width 100%
  padding: 40px 30px
  background var(--gray-4-dark)
  color var(--white)
  +below(1100px) {
  padding 40px 18px
  }
  +below(360px) {
    padding 40px 14px
  }

  &__404 {
    display none
  }

  & .container {
    display flex
    flex-direction column
    gap 60px
    +below(680px) {
      gap 30px
    }
  }

  &__header {
    display flex
    justify-content space-between
    align-items center
    +below(680px) {
    flex-direction column
    gap 30px
    }

    & a{
      font-weight: 500
      color: var(--white)
      font-size: 1.250em
      line-height: 130%;
    }
  }
  &__logo {
    .icon svg path {
      fill var(--white)
    }

    &:hover {
      svg path {
        transition var(--transition)
        fill var(--pink)
      }
    }
  }

  &__info {
    display flex
    justify-content space-between
    align-items center
    +below(680px) {
      flex-direction column
      gap 20px
    }
  }
  &__socials {
    display flex
    gap 37px
    align-items center

    & .icon{
      cursor: pointer;
    }
  }
  &__contact {
    display flex
    gap 50px
    +below(680px) {
      gap 30px
    }
    +below(1100px) {
      gap 15px
    }
  }
  &__contact-title {
    line-height 135%
    opacity 0.8
    +below(680px) {
      font-size 0.75em
    }
    +below(360px) {
      font-size 0.625em
    }
  }
  &__contact-subtitle {
    font-weight: 500;
    font-size: 1.875em
    line-height: 150%;
    opacity 1
    +below(1100px) {
    font-size 1.25em
    }
    +below(680px) {
    font-size 1em
    }
    +below(360px) {
      font-size 0.875em
    }
  }
  &__wrapper {
    display flex
    flex-direction column
  }

  &__bottom {
    display flex
    justify-content space-between
    align-items center

    +below(680px) {
      flex-direction column
      gap 30px
    }

    &-consultation{
      +below(360px){
        span{
          text-transform uppercase
        }
      }
    }

    & .btn {
      text-transform none
      font-weight 600
      font-size 1.125em
      line-height 23px
      +below(680px) {
        width 65%
      }
      +below(550px) {
        width 100%
      }
    }

  }

  &__link {
    display flex
    gap 30px
    opacity 0.8
    +below(1280px) {
      flex-direction column
      gap 5px
      text-align end
    }
    +below(680px) {
      text-align center
    }
  }
}
</style>
