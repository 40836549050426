<template>
  <div id="app">
    <vue-progress-bar />
    <HeaderComponent />
    <Transition mode="out-in" name="page">
      <RouterView :key="$route.fullPath" />
    </Transition>
    <Transition name="bounce">
      <ModalComponent />
    </Transition>
    <FooterComponent />
    <Notifications position="bottom right" />
    <CookiesComponent />
  </div>
</template>

<script>
import ModalComponent from "components/modals/index.vue";
import HeaderComponent from "components/HeaderComponent.vue";
import CookiesComponent from "components/CookiesComponent.vue";
import FooterComponent from "components/FooterComponent.vue";

export default {
  name: "App",
  created() {
    // Полоска загрузки
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  },
  mounted() {
    this.$Progress.finish();
  },
  components: {
    FooterComponent,
    CookiesComponent,
    HeaderComponent,
    ModalComponent,
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
